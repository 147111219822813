import styled from 'styled-components';
import React, { FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import LogoutIcon from '@mui/icons-material/Logout';
import { Container } from '../App';
import logo from '../assets/svg/logo.svg';
import { Button } from '@mui/material';
import { signOutAsync } from '../store/slices/auth/action';

export const Header: FC = () => {
  const {isAuth} = useSelector(({auth}: RootState) => auth)
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <Root>
      <Container>
        <Inner>
          <Logo to="/">
            <img src={logo} alt='' />
          </Logo>
          <Bottom>
            <span/>
            <Title>fast and secure way <span>to share ECG images</span></Title>
            {isAuth && pathname === '/dashboard'
              ? <Button endIcon={<LogoutIcon/>} onClick={() => dispatch(signOutAsync(navigate))}>Log out</Button>
              : <span/>
            }
          </Bottom>
        </Inner>
      </Container>
    </Root>
  );
};

const Root = styled.header`
  padding: 24px 0;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
`;

const Logo = styled(Link)`
  display: block;
  margin-bottom: 24px;

  img {
    display: block;
    height: clamp(60px, 7vw, 72px);
    max-width: 100%;
  }
`;

const Title = styled.h4`
  font-weight: 700;
  font-size: clamp(18px, 2vw, 20px);
  text-transform: uppercase;
  color: #13AEDE;
  text-align: center;
  
  span {
    color: #4FBAAE;
  }
`

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr 150px;
  gap: 32px;
  align-items: center;
  
  @media (max-width: 640px) {
    & > span:first-child {
      display: none;
    }
    
    grid-template-columns: 1fr;
    gap: 16px;
  }
`
