import React, { Dispatch, forwardRef, SetStateAction } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { Button } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export const SortCalendar = forwardRef<HTMLDivElement, CalendarProps>(
  ({ isCalendarVisible, setIsCalendarVisible, setSelectedDates }, ref) => {
    return (
      <Root ref={ref}>
        <InputWrapper
          startIcon={<CalendarTodayIcon />}
          $isVisible={isCalendarVisible}
          onClick={() => setIsCalendarVisible(!isCalendarVisible)}>
          Choose date
        </InputWrapper>
        <CalendarWrapper $isVisible={isCalendarVisible}>
          <Calendar
            prev2Label={null}
            next2Label={null}
            locale="en"
            minDetail="decade"
            selectRange
            allowPartialRange
            onChange={(dates: Date[]) => setSelectedDates(dates.map(dateItem => new Date(dateItem).getTime()))}
          />
        </CalendarWrapper>
      </Root>
    );
  },
);

interface CalendarProps {
  setSelectedDates: Dispatch<SetStateAction<number[]>>;
  setIsCalendarVisible: Dispatch<SetStateAction<boolean>>;
  isCalendarVisible: boolean;
}

const Root = styled.div`
  position: relative;
`;

const CalendarWrapper = styled.div<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 6px 7px 5px 7px;
  width: 250px;
  position: absolute;
  top: calc(100% + 5px);
  background: white;
  z-index: 10;

  .react-calendar__navigation {
    display: flex;
    margin-bottom: 16px;
  }

  .react-calendar__navigation__label {
    border: none;
    background: none;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    flex-grow: 0 !important;
  }

  .react-calendar__navigation__arrow {
    width: 24px;
    height: 24px;
    border: none;
    background-size: contain;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;

    &.react-calendar__navigation__prev-button {
      margin-right: 5px;
    }

    &.react-calendar__navigation__next-button {
      margin-left: 5px;
    }
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 5px;
    justify-content: space-between;
  }

  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    font-size: 8px;
    text-transform: uppercase;

    abbr {
      text-decoration: none;
      font-size: 12px;
    }
  }

  .react-calendar__tile {
    background: none;
    border: none;
    font-size: 14px;
    height: 24px;
    cursor: pointer;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
    }

    &:hover::after {
    }

    &.react-calendar__tile.react-calendar__tile--active,
    &.react-calendar__tile.selected {
      color: white;

      &::after {
        background: #1976d2;
      }
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }
`;

const InputWrapper = styled(Button)<{ $isVisible: boolean }>`
  cursor: pointer;
`;
