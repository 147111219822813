import React, { useEffect } from 'react';
import { getAuth, getRedirectResult } from 'firebase/auth';
import { signInAsync } from '../store/slices/auth/action';
import { setAuth } from '../store/slices/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    const auth = getAuth();
    getRedirectResult(auth)
      .then((result) => {
        if (!result) return dispatch(signInAsync());
        const user = result.user
        const isAdmin = user.email === process.env.REACT_APP_ADMIN_EMAIL ||
          user.email === process.env.REACT_APP_ADDITIONAL_ADMIN_EMAIL ||
          user.email === process.env.REACT_APP_ADDITIONAL_ADMIN2_EMAIL
        dispatch(setAuth(isAdmin))
        if (isAdmin) return navigate('/dashboard')
        navigate('/')
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <div/>
};
