export const QUESTIONS: QuestionItem[] = [
  {
    text: 'Is there a P wave?',
    parentAnswer: 'none',
    answers: ['Yes', 'No', 'Yes - abnormal'],
    stage: 0
  },
  {
    text: 'Is P wave always related to QRS?',
    parentAnswer: 'Yes',
    answers: ['Yes', 'No'],
    stage: 1
  },
  {
    text: 'Choose',
    parentAnswer: 'No',
    answers: ['Sometimes', 'Never'],
    stage: 2
  },
  {
    text: '2° AV Block',
    parentAnswer: 'Sometimes',
    answers: [],
    stage: 3
  },
  {
    text: 'Ventricular Standstill',
    parentAnswer: 'Never',
    answers: [],
    stage: 3
  },
  {
    text: 'Measure PR interval',
    parentAnswer: 'Yes',
    answers: ['Normal', 'Long'],
    stage: 2
  },
  {
    text: 'Sinus',
    parentAnswer: 'Normal',
    answers: [],
    stage: 3
  },
  {
    text: '1° AV Block',
    parentAnswer: 'Long',
    answers: [],
    stage: 3
  },
  {
    text: 'QRS',
    parentAnswer: 'No',
    answers: ['Narrow', 'Wide', 'None'],
    stage: 1,
  },
  {
    text: 'Choose',
    parentAnswer: 'Yes - abnormal',
    answers: ['300/min', 'Inverted pre/post QRS'],
    stage: 1,
  },
  {
    text: 'AFlutter',
    parentAnswer: '300/min',
    answers: [],
    stage: 2,
  },
  {
    text: 'Junctional',
    parentAnswer: 'Inverted pre/post QRS',
    answers: [],
    stage: 2,
  },
  {
    text: 'Choose',
    parentAnswer: 'Narrow',
    answers: [{ text: 'Regular', id: 'Atrial Junctional' }, { text: 'Irregular', id: 'AF' }],
    stage: 2
  },
  {
    text: 'Choose',
    parentAnswer: 'Wide',
    answers: [{ text: 'Regular', id: 'Ventricular' }, { text: 'Irregular', id: 'Aberrant AF Ventricular' }],
    stage: 2
  },
  {
    text: 'VF Asystole',
    parentAnswer: 'None',
    answers: [],
    stage: 2
  },
  {
    text: 'Atrial Junctional',
    parentAnswer: 'Regular',
    answers: [],
    stage: 3
  },
  {
    text: 'AF',
    parentAnswer: 'Irregular',
    answers: [],
    stage: 3
  },
  {
    text: 'Ventricular',
    parentAnswer: 'Regular',
    answers: [],
    stage: 3
  },
  {
    text: 'Aberrant AF Ventricular',
    parentAnswer: 'Irregular',
    answers: [],
    stage: 3
  },
]

export type QuestionItem = {
  text: string,
  parentAnswer: string,
  answers: { text: string, id: string }[] | string[],
  stage: number
}
