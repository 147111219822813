import { createSlice } from '@reduxjs/toolkit';
import { fetchEcgAsync, fetchEcgByCodeAsync } from './action';
import { EcgState, EcgObject } from './types';

const initialState: EcgState = {
  ecg: null,
  isEcgNull: false,
  allEcg: [],
};

const ecgSlice = createSlice({
  name: 'ecg',
  initialState,
  reducers: {
    replaceEcg: (state, {payload}) => {
      state.allEcg = state.allEcg.map(ecg => {
        const found = ecg.imgUrls.find(url => url === payload.ecgUrl)
        if (found) {
          return {
            ...ecg,
            imgUrls: [...ecg.imgUrls.filter(url => url !== payload.ecgUrl), payload.replaceTo]
          }
        }
        return ecg
      })
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchEcgByCodeAsync.fulfilled, (state, { payload }) => {
      state.isEcgNull = !payload;
      state.ecg = payload as EcgObject | null;
    });
    builder.addCase(fetchEcgAsync.fulfilled, (state, { payload }) => {
      state.allEcg = payload as EcgObject[];
    });
  },
});

export default ecgSlice.reducer;
export const { replaceEcg } = ecgSlice.actions
export { fetchEcgAsync, fetchEcgByCodeAsync } from './action';
