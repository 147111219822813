import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import rotateIcon from '../assets/svg/rotate.png';
import { useRotateImage } from '../hooks';
import { CircularProgress } from '@mui/material';

export const Popup: FC<PopupProps> = ({ isPopupVisible, setIsPopupVisible, activeImgUrl , rotate}) => {
  const [imgUrl, setImgUrl] = useState(activeImgUrl)
  const [loading, setLoading] = useState(false)
  const rotateImage = useRotateImage({
    url: activeImgUrl,
    setImgUrl,
    setLoading,
    updateOnDatabase: true
  })

  const closePopup = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') setIsPopupVisible(false);
  };

  const handleGoBack = (e: PopStateEvent) => {
    if (isPopupVisible) {
      e.preventDefault();
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', closePopup);
  }, []);

  useEffect(() => {
    setImgUrl(activeImgUrl)
  }, [activeImgUrl])

  useEffect(() => {
    if (isPopupVisible) {
      window.history.pushState(null, '', window.location.pathname);
    }
    window.addEventListener('popstate', handleGoBack);

    return () => window.removeEventListener('popstate', handleGoBack);
  }, [isPopupVisible]);

  return (
    <Root $isPopupVisible={isPopupVisible}>
      <OuterClose onClick={() => setIsPopupVisible(false)} />
      <Image>
        <Close onClick={() => setIsPopupVisible(false)} />
        <img src={imgUrl} alt="" />
        <Footer>
          {rotate && (
            loading ? <CircularProgress/> : <RotateIcon onClick={rotateImage}/>
          )}
        </Footer>
      </Image>
    </Root>
  );
};

interface PopupProps {
  isPopupVisible: boolean
  setIsPopupVisible: Dispatch<SetStateAction<boolean>>
  activeImgUrl: string
  rotate?: boolean
}

const Root = styled.div<{ $isPopupVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 100;
  display: flex;
  justify-content: center;
  transition: 0.2s;
  ${({ $isPopupVisible }) =>
    !$isPopupVisible &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;

const OuterClose = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-out;
`;

const Image = styled.div`
  position: relative;
  z-index: 10;
  align-self: center;
  height: 100%;
  width: 100%;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 24px;
  right: 40px;
  width: 32px;
  height: 32px;
  display: flex;
  padding: 6px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  @media (max-width: 425px) {
    right: 24px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 2px;
    background: white;
    transform: translateX(7px) rotate(45deg);
  }

  &::before {
    transform: translateX(9px) rotate(-45deg);
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
`

const RotateIcon = styled.div`
  width: 32px;
  height: 32px;
  background: url(${rotateIcon}) no-repeat center;
  background-size: contain;
  cursor: pointer;
`
