import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, GoogleAuthProvider, signOut, signInWithRedirect } from 'firebase/auth';
import { NavigateFunction } from 'react-router-dom';

export const signInAsync = createAsyncThunk('auth/signInAsync',
  async () => {
  try {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    await signInWithRedirect(auth, provider);
  } catch (e) {
    console.log(e);
  }
});

export const signOutAsync = createAsyncThunk('auth/signInAsync', async (navigate: NavigateFunction) => {
  try {
    const auth = getAuth();
    await signOut(auth);
    navigate('/');
  } catch (e) {
    console.log(e);
  }
});
