import './assets/font-SFPro/stylesheet.css';
import React, { FC, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Auth, Dashboard, Home } from './pages';
import { Header } from './components';
import { RootState } from './store';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setAuth } from './store/slices/auth';

export const App: FC = () => {
  const { isAuth } = useSelector(({ auth }: RootState) => auth);
  const dispatch = useDispatch()
  const { pathname } = useLocation();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const isAdmin =
          user.email === process.env.REACT_APP_ADMIN_EMAIL ||
          user.email === process.env.REACT_APP_ADDITIONAL_ADMIN_EMAIL ||
          user.email === process.env.REACT_APP_ADDITIONAL_ADMIN2_EMAIL
        dispatch(setAuth(isAdmin))
      }
    });
  }, []);

  return (
    <Root $isMainPage={pathname === '/'}>
      <GlobalStyle />
      {pathname !== '/print' && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Auth />} />
        {isAuth && <Route path="/dashboard" element={<Dashboard />} />}
      </Routes>
    </Root>
  );
};

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    outline: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-family: 'SF Pro Display', sans-serif !important;
  }
`;

const Root = styled.div<{$isMainPage: boolean}>`
  ${({$isMainPage}) => $isMainPage && css`
    background: radial-gradient(95.38% 115.1% at 50.29% 50.97%, rgba(79, 186, 174, 0) 0%, rgba(79, 186, 174, 0.51) 100%);
  `}
`

export const Container = styled.div`
  max-width: 1232px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
`;
