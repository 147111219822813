import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { useConvertDate, useRotateImage } from '../hooks';
import rotateIcon from '../assets/svg/rotate.png';

export const SearchResult: FC<SearchResultProps> = ({ url, date, openPopup }) => {
  const [imgUrl, setImgUrl] = useState(url)
  const rotateImage = useRotateImage({ url, setImgUrl })

  const copyToClipboard = (crop: boolean) => {
    const img = new Image
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    img.crossOrigin = 'anonymous'

    const promise = () => new Promise(resolve => {
      img.onload = () => {
        const imgHeight = Number((img.height * (800 / img.width)).toFixed())
        img.width = crop ? 800 : img.width
        img.height = crop ? imgHeight : img.height
        canvas.width = crop ? 800 : img.width
        canvas.height = crop ? imgHeight : img.height
        ctx!.drawImage(img,0,0, img.width, img.height)
        canvas.toBlob(blob => resolve(blob),'image/png')
      }
      img.src = imgUrl
    })
    navigator.clipboard.write([new ClipboardItem({'image/png': promise() as PromiseLike<ClipboardItemDataType>})])
      .then(() => console.log('success'))
      .catch(() => console.log('error'))
  }

  return (
    <Root>
      <ResultItem>
        <ResultImage src={imgUrl} alt="" className="content" onClick={() => openPopup(imgUrl)} />
        <RotateIcon onClick={rotateImage}/>
      </ResultItem>
      <DateTime className="searchDate">{useConvertDate(date)}</DateTime>
      <Buttons className="searchButtons">
        <Button variant="contained" onClick={() => copyToClipboard(false)}>
          Copy to clipboard full size
        </Button>
        <Button variant="contained" onClick={() => copyToClipboard(true)}>Copy to clipboard 800px width</Button>
      </Buttons>
    </Root>
  );
};

interface SearchResultProps {
  url: string;
  date: number;
  openPopup: (url: string) => void;
}

const Root = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin: 8px;
`;

const DateTime = styled.div`
  margin-top: 6px;
  font-size: 12px;
`;

const Buttons = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 6px;

  .MuiButton-root {
    font-size: 12px;
    padding: 4px 8px;
    line-height: 150%;
  }
`;

const ResultItem = styled.div`
  position: relative;
  margin-bottom: auto;
  max-height: 500px;

  .content {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ResultImage = styled.img`
  cursor: zoom-in;
`

const RotateIcon = styled.div`
  width: 32px;
  height: 32px;
  background: url(${rotateIcon}) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 16px;
`
