import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container } from '../App';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEcgAsync } from '../store/slices/ecg';
import { RootState } from '../store';
import { Popup, SortCalendar } from '../components';
import { Button } from '@mui/material';
import sort from '../assets/svg/sort.svg';

export const Dashboard: FC = () => {
  const { allEcg } = useSelector(({ ecg }: RootState) => ecg);
  const [selectedDates, setSelectedDates] = useState<number[]>([]);
  const [activeImgUrl, setActiveImgUrl] = useState('');
  const [sort, setSort] = useState<'newFirst' | 'oldFirst'>('newFirst');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const calendarRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const toDate = (dateNumber: number) => {
    const date = new Date(dateNumber);

    return (
      <Fragment>
        <div>{date.toLocaleDateString()}</div>
        <div>{date.toLocaleTimeString().slice(0, -3)}</div>
      </Fragment>
    );
  };

  const openPopup = (url: string) => {
    setActiveImgUrl(url);
    setIsPopupVisible(true);
  };

  const reload = () => {
    setSelectedDates([]);
    dispatch(fetchEcgAsync({ setIsLoading, reload: true }));
  };

  const outerClick = (e: Event) => {
    if (!calendarRef.current || !calendarRef.current.contains(e.target as Node | null)) {
      setIsCalendarVisible(false);
    }
  };

  const onSort = () => {
    setSort(sort => (sort === 'newFirst' ? 'oldFirst' : 'newFirst'));
    dispatch(
      fetchEcgAsync({
        setIsLoading,
        sort: sort === 'newFirst' ? 'oldFirst' : 'newFirst',
        datesInSeconds: selectedDates,
      }),
    );
  };

  const setCode = (code: string) => {
    let newCode = [...code];
    newCode.splice(3, 0, '-');
    return newCode.join('');
  };

  useEffect(() => {
    document.addEventListener('click', outerClick, true);
    dispatch(
      fetchEcgAsync({
        setIsLoading,
        datesInSeconds: selectedDates,
      }),
    );

    return () => document.removeEventListener('click', outerClick, true);
  }, []);

  return (
    <Root>
      <Container>
        <Header>
          <Button startIcon={<ReplayIcon />} onClick={reload}>
            Reload
          </Button>
          <SortCalendar
            isCalendarVisible={isCalendarVisible}
            setIsCalendarVisible={setIsCalendarVisible}
            setSelectedDates={setSelectedDates}
            ref={calendarRef}
          />
          {selectedDates.length !== 0 && (
            <Button
              startIcon={<SearchIcon />}
              onClick={() => dispatch(fetchEcgAsync({ setIsLoading, datesInSeconds: selectedDates }))}>
              Search
            </Button>
          )}
        </Header>
        <TableWrap>
          <Table>
            <TableRow>
              <DateSort onClick={onSort}>Date</DateSort>
              <div>Images</div>
              <div>Code</div>
              <div>Comment</div>
            </TableRow>
            {!isLoading &&
              allEcg.map(item => (
                <TableRow key={item.code}>
                  <div>
                    <div className="date">{toDate(item.date)}</div>
                  </div>
                  <Images>
                    {item.imgUrls.map(url => (
                      <div key={url} onClick={() => openPopup(url)}>
                        <img src={url} alt="" />
                      </div>
                    ))}
                  </Images>
                  <div>{setCode(item.code)}</div>
                  <div>{item.comment}</div>
                </TableRow>
              ))}
            {isLoading && <Loading>Loading...</Loading>}
            {!isLoading && allEcg.length === 0 && <Loading>No ECG</Loading>}
          </Table>
        </TableWrap>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() =>
            dispatch(fetchEcgAsync({ setIsLoading, prev: true, sort, datesInSeconds: selectedDates, setSort }))
          }>
          prev
        </Button>
        <Button
          endIcon={<ArrowForwardIcon />}
          onClick={() =>
            dispatch(fetchEcgAsync({ setIsLoading, next: true, sort, datesInSeconds: selectedDates, setSort }))
          }>
          next
        </Button>
        {activeImgUrl && (
          <Popup
            rotate
            isPopupVisible={isPopupVisible}
            setIsPopupVisible={setIsPopupVisible}
            activeImgUrl={activeImgUrl}
          />
        )}
      </Container>
    </Root>
  );
};

const Root = styled.div`
  padding-bottom: 24px;
`;

const TableWrap = styled.div`
  overflow-x: auto;
  margin-bottom: 16px;
`;

const Table = styled.div`
  border-radius: 4px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 1000px;
`;

const TableRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 4fr 1fr 4fr;
  padding: 16px;

  & > div {
    padding: 0 8px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .date {
    font-size: 12px;
    display: inline-grid;
    justify-items: center;
    gap: 6px;
  }
`;

const Header = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  margin: 24px 0;
`;

const Images = styled.div`
  display: flex;
  overflow-x: auto;

  & > div {
    flex-shrink: 0;
    width: clamp(70px, 7vw, 85px);

    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  img {
    display: block;
    width: 100%;
    cursor: zoom-in;
  }
`;

const Loading = styled.div`
  padding: 24px;
  text-align: center;
`;

const DateSort = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: url(${sort}) no-repeat center;
    background-size: contain;
    margin-left: 8px;
  }
`;
