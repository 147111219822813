import { combineReducers, configureStore } from '@reduxjs/toolkit';
import ecg from './slices/ecg';
import auth from './slices/auth';

const rootReducer = combineReducers({
  ecg,
  auth,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof rootReducer>;
