import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  getFirestore,
  orderBy,
  query,
  where,
  limit,
  limitToLast,
  startAfter,
  startAt,
  endAt,
  endBefore,
  arrayRemove,
  arrayUnion
} from 'firebase/firestore';
import { EcgObject, FetchEcgAsync, FetchEcgByCodeAsync } from './types';
import { RootState } from '../../index';
import { replaceEcg } from './index';
import { Dispatch, SetStateAction } from 'react';

export const fetchEcgAsync = createAsyncThunk(
  'ecg/fetchEcgAsync',
  async ({ setIsLoading, sort, datesInSeconds, next, prev, reload, setSort }: FetchEcgAsync, { getState }) => {
    try {
      setIsLoading(true);
      const { ecg } = getState() as RootState;
      const ecgRef = collection(getFirestore(), 'ecg');
      const selectedDates = datesInSeconds;
      let q;

      if (selectedDates?.length === 1) {
        selectedDates.push(selectedDates[0] + 86398999);
      }

      if (reload) {
        q = query(ecgRef, orderBy('date', 'desc'), limit(10));
      } else if (next && selectedDates && selectedDates.length !== 0) {
        if (ecg.allEcg.length === 0) {
          q = query(ecgRef, orderBy('date', 'desc'), limit(10));
        } else {
          q = query(
            ecgRef,
            where('date', '>=', selectedDates[0]),
            where('date', '<=', selectedDates[1]),
            orderBy('date', 'desc'),
            startAfter(ecg.allEcg[sort === 'newFirst' ? ecg.allEcg.length - 1 : 0].date),
            limit(10),
          );
        }
        setSort!('newFirst');
      } else if (prev && selectedDates && selectedDates.length !== 0) {
        if (ecg.allEcg.length === 0) {
          q = query(ecgRef, orderBy('date', 'desc'), limit(10));
        } else {
          q = query(
            ecgRef,
            where('date', '>=', selectedDates[0]),
            where('date', '<=', selectedDates[1]),
            orderBy('date', 'desc'),
            endBefore(ecg.allEcg[0].date),
            limitToLast(11),
          );
        }
        setSort!('newFirst');
      } else if (selectedDates && selectedDates.length !== 0) {
        if (sort) {
          q = query(
            ecgRef,
            where('date', '>=', selectedDates[0]),
            where('date', '<=', selectedDates[1]),
            orderBy('date', sort === 'newFirst' ? 'desc' : 'asc'),
            startAt(ecg.allEcg[ecg.allEcg.length - 1].date),
            endAt(ecg.allEcg[0].date),
            limit(10),
          );
        } else {
          q = query(
            ecgRef,
            where('date', '>=', selectedDates[0]),
            where('date', '<=', selectedDates[1]),
            orderBy('date', 'desc'),
            limit(10),
          );
        }
      } else if (next && sort) {
        if (ecg.allEcg.length === 0) {
          q = query(ecgRef, orderBy('date', 'desc'), limit(10));
        } else {
          q = query(
            ecgRef,
            orderBy('date', 'desc'),
            startAfter(ecg.allEcg[sort === 'newFirst' ? ecg.allEcg.length - 1 : 0].date),
            limit(10),
          );
        }
        setSort!('newFirst');
      } else if (prev && sort) {
        if (ecg.allEcg.length === 0) {
          q = query(ecgRef, orderBy('date', 'desc'), limit(10));
        } else {
          q = query(ecgRef, orderBy('date', 'desc'), endBefore(ecg.allEcg[0].date), limitToLast(11));
        }
        setSort!('newFirst');
      } else if (sort && ecg.allEcg.length !== 0) {
        q = query(
          ecgRef,
          orderBy('date', sort === 'newFirst' ? 'desc' : 'asc'),
          startAt(ecg.allEcg[ecg.allEcg.length - 1].date),
          endAt(ecg.allEcg[0].date),
          limit(10),
        );
      } else {
        q = query(ecgRef, orderBy('date', 'desc'), limit(10));
      }
      const ecgSnapshot = await getDocs(q);
      const ecgArr: EcgObject[] = [];

      setIsLoading(false);
      ecgSnapshot.forEach(snapshot => ecgArr.push(snapshot.data() as EcgObject));
      return ecgArr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const fetchEcgByCodeAsync = createAsyncThunk(
  'ecg/fetchEcgByCodeAsync',
  async ({ code, setIsFetching }: FetchEcgByCodeAsync) => {
    try {
      const docSnap = await getDoc(doc(getFirestore(), 'ecg', code));
      setIsFetching(false);
      return docSnap.exists() ? docSnap.data() : null;
    } catch (e) {
      console.log(e);
    }
  },
);

export const updateEcgOrientation = createAsyncThunk(
  'ecg/fetchEcgByCodeAsync',
  async (ecg: {url: string, ecgBase64: string, setImgUrl: Dispatch<SetStateAction<string>>}, {dispatch}) => {
    try {
      const urlSplit = ecg.url.split('/')
      const image_name = urlSplit.pop()
      const ecg_id = urlSplit.pop()
      const image_file = ecg.ecgBase64.split(',').pop()

      const res = await fetch('https://ecg-backend-tcr2w7j4uq-uc.a.run.app/api/update_image/', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ image_name, ecg_id, image_file })
      })
      const {url} = await res.json()
      const ecgRef = doc(getFirestore(), 'ecg', `${ecg_id}`)
      const deleteEcg = updateDoc(ecgRef, {
        imgUrls: arrayRemove(ecg.url)
      })
      const addEcg = updateDoc(ecgRef, {
        imgUrls: arrayUnion(url)
      })
      await Promise.all([deleteEcg, addEcg])
      dispatch(replaceEcg({ecgUrl: ecg.url, replaceTo: url}))
      ecg.setImgUrl(url)
    } catch (e) {
      console.log(e);
    }
  },
);

