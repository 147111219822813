import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateEcgOrientation } from '../store/slices/ecg/action';

interface Props {
  url: string
  setImgUrl: Dispatch<SetStateAction<string>>
  setLoading?: Dispatch<SetStateAction<boolean>>
  updateOnDatabase?: boolean
}

export const useRotateImage = ({url, setImgUrl, setLoading, updateOnDatabase}: Props) => {
  const [rotationDeg, setRotationDeg] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    setRotationDeg(0)
  }, [url])

  return () => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = url
    const canvas = document.createElement('canvas')

    const rotationDegValue = () => {
      switch (rotationDeg) {
        case 0: return Math.PI
        default: return 360 * Math.PI / 180
      }
    }

    img.onload = async () => {
      if (setLoading) {
        setLoading(true)
      }
      const ctx = canvas.getContext('2d')

      canvas.width = img.width
      canvas.height = img.height
      ctx!.translate(canvas!.width / 2, canvas!.height / 2)
      ctx!.rotate(rotationDegValue());
      ctx!.drawImage(img, -img.width / 2, -img.height / 2);
      setRotationDeg(deg => deg === 0 ? 180 : 0)
      if (updateOnDatabase) {
        await dispatch(updateEcgOrientation({url, ecgBase64: canvas.toDataURL('image/jpeg'), setImgUrl}))
      } else {
        setImgUrl(canvas.toDataURL('image/jpeg'))
      }
      if (setLoading) {
        setLoading(false)
      }
    }
  }
}
