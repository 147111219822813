import React, { ChangeEvent, FC, FormEvent, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { fetchEcgByCodeAsync } from '../store/slices/ecg';
import { RootState } from '../store';
import { Container } from '../App';
import { BotQuestionnaire, Footer, Popup, SearchResult } from '../components';
import { useConvertDate } from '../hooks';
import { useNavigate } from 'react-router-dom';

export const Home: FC = () => {
  const { ecg, isEcgNull } = useSelector(({ ecg }: RootState) => ecg);
  const [search, setSearch] = useState('');
  const [activeImgUrl, setActiveImgUrl] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSearchError, setIsSearchError] = useState(false);
  const [isSearchDirty, setIsSearchDirty] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openPopup = (url: string) => {
    setActiveImgUrl(url);
    setIsPopupVisible(true);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const arrValue = value.split('');
    setSearch(
      value.length <= 7 && /[a-z\d, -]/i.test(arrValue[arrValue.length - 1])
        ? value.length === 3 && !search.includes('-')
          ? value + '-'
          : value
        : search,
    );
    setIsSearchError(isSearchDirty && value.length < 7);
  };

  const validateSearch = () => {
    setIsSearchError(search.length < 6);
    setIsSearchDirty(true);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (search.length < 6) {
      setIsSearchError(true);
      return;
    }
    setIsFetching(true);
    dispatch(fetchEcgByCodeAsync({ code: search.replace('-', '').toUpperCase(), setIsFetching }));
  };

  useEffect(() => {
    return () => {
      if (isPopupVisible) {
        navigate('/');
      }
    };
  }, [isPopupVisible]);

  return (
    <Fragment>
      <Container>
        <Root>
          <CodeTime>
            <span>{ecg?.code}</span>
            <span>{useConvertDate(ecg?.date || 0)}</span>
          </CodeTime>
          <Form onSubmit={handleSubmit}>
            <TextField
              autoComplete="off"
              value={search}
              onChange={handleSearch}
              onBlur={validateSearch}
              variant="standard"
              label="Enter code"
              error={isSearchError}
              helperText={isSearchError ? 'Should be 6 characters' : 'e.g. XYZ-345'}
            />
            <LoadingButton loading={isFetching} type="submit" variant="contained">
              Get
            </LoadingButton>
          </Form>
          <ResultWrap>
            {ecg && (
              <Fragment>
                <Result>
                  {ecg.imgUrls.map(url => (
                    <SearchResult key={url} url={url} date={ecg.date} openPopup={openPopup} />
                  ))}
                </Result>
                <Button variant="contained" onClick={() => window.print()}>
                  Print to PDF
                </Button>
              </Fragment>
            )}
          </ResultWrap>
          {!ecg && isEcgNull && <Error>No such ECG</Error>}
        </Root>
        <Popup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} activeImgUrl={activeImgUrl} />
      </Container>
      <Footer />
      <BotQuestionnaire/>
    </Fragment>
  );
};

const Form = styled.form`
  max-width: 300px;
  width: 100%;

  .MuiTextField-root {
    width: 100%;
    margin-bottom: clamp(16px, 2vw, 24px);

    .MuiInputBase-input {
      text-transform: uppercase;
    }
  }

  .MuiButton-root {
    width: 100%;
  }
`;

const ResultWrap = styled.div`
  position: relative;
  display: inline-grid;
  gap: 24px;
  justify-items: center;

  & > a {
    text-decoration: none;
  }
`;

const Result = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const Error = styled.div`
  font-size: 24px;
  margin: 50px 0;
`;

const CodeTime = styled.div`
  font-size: 24px;
  position: fixed;
  top: 16px;
  left: 0;
  display: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 101;

  span:last-child {
    font-size: 16px;
  }
`;

const Root = styled.div`
  padding: 24px 0;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: clamp(8px, 2vw, 24px);

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }

  @media print {
    @page {
      margin: 0 auto;
    }

    ${CodeTime} {
      display: flex;
    }

    ${Result} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      position: fixed;
      padding-top: 70px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 100;

      & > div {
        max-width: none;
        margin: 0;
      }
    }

    .searchButtons,
    .searchDate {
      display: none;
    }
  }
`;
