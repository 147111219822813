import 'swiper/css';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { Container } from '../App';
import appStore from '../assets/svg/appStore.png'
import googlePlay from '../assets/svg/googlePlay.png'
import mobile from '../assets/svg/mobile.svg'
import image from '../assets/svg/image.png'

export const Footer: FC = () => {
  return (
    <Root>
      <Container>
        <Stores>
          <div>
            <h4>Available at stores</h4>
            <div>
              <a href="#!" target="_blank"><img src={appStore} alt='' /></a>
              <a href="#!" target="_blank"><img src={googlePlay} alt='' /></a>
            </div>
          </div>
        </Stores>
      </Container>
      <InfoWrap>
        <Container>
          <InfoLeft>
            <div>
              <InfoTitle>What is Fastlane.ecg.coach</InfoTitle>
              <InfoText>
                Fastlane.ecg.coach is a fast and secure way to share ECG images between healthcare providers.
                The images are corrected to enhance readability without distorting the registration.
              </InfoText>
            </div>
            <div>
              <InfoTitle>Easy to use</InfoTitle>
              <InfoText>The use of fastlane.ecg.coach is straightforward:</InfoText>
              <ul style={{paddingLeft: 20}}>
                <li>
                  <InfoText>download the iOS or Android app</InfoText>
                </li>
                <li>
                  <InfoText>make a picture of an ECG</InfoText>
                </li>
                <li>
                  <InfoText>use the crop markers to adjust the image when needed</InfoText>
                </li>
                <li>
                  <InfoText>click ‘share ECG’</InfoText>
                </li>
                <li>
                  <InfoText>call the hospital or healthcare provider you want to share the ECG with</InfoText>
                </li>
                <li>
                  <InfoText> tell them to go to the ecg.coach website</InfoText>
                </li>
                <li>
                  <InfoText>and provide them with the 6 characters code.</InfoText>
                </li>
                <li>
                  <InfoText>now they can watch the ECG and copy paste it as need</InfoText>
                </li>
              </ul>
            </div>
          </InfoLeft>
          <div>
            <MobileWrap>
              <MobileSwiper
                loop={true}
                modules={[Autoplay]}
                // autoplay={{delay: 2000}}
              >
                <SwiperSlide>
                  <img src={image} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image} alt='' />
                </SwiperSlide>
              </MobileSwiper>
              <MobileImage src={mobile} />
            </MobileWrap>
          </div>
        </Container>
      </InfoWrap>
      <Container style={{maxWidth: 900, marginTop: 16}}>
        <ContactsTitle>Contacts</ContactsTitle>
        <InfoText>Contact us at <a href="mailto:info@augmentedheart.com">info@augmentedheart.com</a></InfoText>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  padding-bottom: clamp(24px, 9vw, 100px);
`

const Stores = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  
  h4 {
    font-weight: 400;
    font-size: clamp(24px, 3vw, 36px);
    color: #000000;
    margin-bottom: 24px;
  }
  
  div div {
    display: inline-grid;
    grid-template-columns: auto auto;
    gap: 24px;
  }
  
  a {
    display: block;
    
    img {
      display: block;
      height: clamp(40px, 6vw, 60px);
    }
  }
`

const InfoWrap = styled.div`
  position: relative;
  padding: 45px 0;
  
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% - 120px);
    position: absolute;
    background: rgba(227, 231, 255, 0.57);
    top: 0;
    left: 0;
    
    @media (max-width: 1024px) {
      height: 100%;
    }
  }
  
  & > div {
    display: grid;
    grid-template-columns: 4.7fr 3.3fr;
    gap: clamp(40px, 10vw, 100px);
    max-width: 900px;

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }
  }
`

const InfoLeft = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  gap: clamp(40px, 10vw, 100px);
  align-content: start;
`

const InfoTitle = styled.h2`
  font-weight: 400;
  font-size: clamp(24px, 3vw, 36px);
  color: #24ACDD;
  margin-bottom: 24px;
`

const InfoText = styled.p`
  font-size: 14px;
  line-height: 125%;
  color: #000000;
  
  a {
    color: #24ACDD;
  }
`

const MobileWrap = styled.div`
  max-width: clamp(250px, 32vw, 330px);
  width: 100%;
  position: relative;
  margin-top: -20px;

  @media (max-width: 640px) {
    margin: 0 auto;
    max-width: 200px;
  }
`

const MobileImage = styled.img`
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
`

const MobileSwiper = styled(Swiper)`
  position: absolute !important;
  bottom: 10px;
  left: 12px;
  height: calc(100% - 42px);
  width: calc(100% - 24px);
  
  @media (max-width: 640px) {
    height: calc(100% - 32px);
  }
  
  .swiper-slide {
    background: linear-gradient(351.13deg, #F2F2F2 1.44%, #FFFFFF 112.15%);
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const ContactsTitle = styled.h4`
  font-weight: 400;
  font-size: clamp(24px, 3vw, 36px);
  margin-bottom: 16px;
  color: #000000;
`
