import { MONTHS } from '../appConstants';

export const useConvertDate = (dateNumber: number) => {
  const date = new Date(dateNumber);
  const year = date.getFullYear();
  const month = MONTHS[date.getMonth()];
  const day = date.getDate();
  const time = date.toLocaleTimeString().slice(0, -3);
  return `ECG ${month} ${day < 10 ? `0${day}` : day} ${year} ${time}`;
};
