import React, { FC, Fragment, useState } from 'react';
import { Chat, ArrowBack } from '@mui/icons-material';
import { Button, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import styled, { css } from 'styled-components';
import { QUESTIONS } from '../appConstants';
import { QuestionItem } from '../appConstants/botQuestionnaire';

export const BotQuestionnaire: FC = () => {
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [result, setResult] = useState('');
  const [messages, setMessages] = useState<QuestionItem[]>([]);
  const [activeQuestion, setActiveQuestion] = useState<QuestionItem>(QUESTIONS[0]);
  const [answer, setAnswer] = useState('');

  const onAnswerSubmit = () => {
    if (answer) setMessages(state => [...state, { ...activeQuestion, answers: [answer] }]);
    const similarQuestions = QUESTIONS.filter(({ stage, answers, parentAnswer, text }) => {
      return stage === activeQuestion.stage + 1 && parentAnswer === answer;
    });
    let el: QuestionItem;
    activeQuestion.answers.forEach(item => {
      const answer = typeof item === 'object' ? item.id : item;
      const found = similarQuestions.find(item => {
        const textAnswer = typeof item === 'object' ? item.text : item;
        return answer === textAnswer;
      })!;
      if (found) el = found
    });
    const newActiveQuestion = el! || similarQuestions![0];
    if (newActiveQuestion.answers.length === 0) {
      setResult(newActiveQuestion.text);
      setMessages([]);
      setEnd(true);
    }
    setActiveQuestion(newActiveQuestion);
    setAnswer('');
  };

  const onStart = () => {
    setActiveQuestion(QUESTIONS[0]);
    setStart(true);
    setEnd(false);
  };

  return (
    <Fragment>
      <Root $showChat={showChat}>
        <Header>
          <IconButton onClick={() => setShowChat(false)}>
            <ArrowBack style={{ fill: 'white' }} />
          </IconButton>
        </Header>
        <Content>
          {!start && <Button style={{ margin: 'auto' }} onClick={onStart}>Start</Button>}
          {end && (
            <Result>
              <p style={{ textAlign: 'center' }}>Result: {result}</p>
              <Button onClick={onStart}>Try again</Button>
            </Result>
          )}
          {start && !end && (
            <Fragment>
              <Messages>
                {messages.map(({ text, answers }, i) => {
                  const userText = typeof answers[0] === 'object' ? answers[0].text : answers[0];
                  return (
                    <Fragment key={i}>
                      <Message $bot>{text}</Message>
                      <Message $user>{userText}</Message>
                    </Fragment>
                  );
                })}
                <Message $bot>{activeQuestion.text}</Message>
                <Message $user $activeAnswer>
                  <RadioGroup name='name'>
                    {activeQuestion.answers.map((item, i) => {
                      const text = typeof item === 'object' ? item.text : item;
                      return (
                        <FormControlLabel
                          key={i}
                          checked={answer === text}
                          onChange={() => setAnswer(text)}
                          value={text}
                          control={<Radio size='small' />}
                          label={text}
                        />
                      );
                    })}
                  </RadioGroup>
                </Message>
              </Messages>
              <Button style={{ marginTop: 'auto' }} onClick={onAnswerSubmit}>Submit</Button>
            </Fragment>
          )}
        </Content>
      </Root>
      <ShowChatButton onClick={() => setShowChat(true)}>
        <Chat style={{ fill: '#21a5cd', fontSize: 36 }} />
      </ShowChatButton>
    </Fragment>
  );
};

const Root = styled.div<{ $showChat: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  transform: scale(${({ $showChat }) => $showChat ? 1 : 0});
  transform-origin: bottom right;
  transition: .4s;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);

  @media (min-width: 1024px) {
    max-width: 400px;
    height: auto;
    bottom: 16px;
    right: 16px;
    border-radius: 10px;
    overflow: hidden;
  }
`;

const Header = styled.div`
  background: #1976d2;
  padding: 8px;
`;

const Content = styled.div`
  background: #fafafa;
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);

  @media (min-width: 1024px) {
    height: 470px;
  }
`;

const Messages = styled.div`
  display: grid;
  gap: 16px;
  align-content: start;
`;

const Message = styled.div<{ $bot?: boolean, $user?: boolean, $activeAnswer?: boolean }>`
  padding: 8px 12px;
  color: #212529;
  border-radius: 8px;
  max-width: 70%;
  margin-right: auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .05);
  background: ${({ $bot, $user }) => $bot ? '#eaeaea' : $user && '#cafacc'};

  ${({ $user, $activeAnswer }) => $user && css`
    ${$activeAnswer && css`padding: 4px 16px 4px 12px;`}
    margin-left: auto;
    margin-right: 0;
  `}
  label {
    margin-right: 0;
  }
`;

const Result = styled.div`
  margin: auto;
  display: grid;
  gap: 16px;
`;

const ShowChatButton = styled(IconButton)`
  position: fixed !important;
  bottom: 16px;
  right: 16px;
  width: 80px;
  height: 80px;
  z-index: 10;
`;
